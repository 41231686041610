// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #000000;
$warning: #d4c251;
$success: #477d60;
$danger: #733049;

// override main menu colors
$menu-bg: #730d3d;
$menu-dropdown-bg: #730d3d;

$menu-sm-bg: #730d3d; // mobile main menu header bg color
$menu-sm-fg: #ffffff; // mobile main menu header fg color

// override Campaign outlet button color
$outlet-btn-color: #730d3d;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
// $buygift-btn-color: $dark;

// override route links color
$link-color: #b90b0b;

$footer: #2b292a;
$footer-brand: #1d1c1c;
.footer-links-column {
  border-right: 1px solid #f8f9fa36 !important;
}

$product-series-summary-bg: #343a3f !important;

// override product subcategory color
$product-subcategory-bg: #e7e4e4;
$product-subcategory-fg: $dark;

.dropdown-menu {
  border-radius: none;
}

.navbar-brand {
  padding: 0.8rem;
}

// override product discount ribbon color
$discount-ribbon-bg: #5B1E35;
$discount-ribbon-fg: $white;

.valentines {
  background-color: #df7899 !important;
}

.cybermonday {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.winecellar {
  background-color: #f9f4f5 !important;
}

.greybox {
    background-color: #ebe9e9;
}

.christmas{
  background-color: #a3ab9e !important;
}

.mellandagsrea{
  background-color: #01224b !important;
}

.easter {
  background-color: #f37934 !important;
}

.monthly {
  background-color: #f9f4f5 !important;
}

.monthlytwo {
  background-color: #ffa30c !important;
}

.blackmonth {
  background-color: #000000 !important;
}

.cyber {
  background-color: #007ea6 !important;
}